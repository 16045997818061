<template>
  <div class="report-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item>
          <i class="icon-home"></i>监测服务
        </Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box">
        <h2 class="search-title border-bottom">按条件搜索</h2>
        <Row class="search-row">
          <Col span="4">
            <span class="sub-label">监测数据类别：</span>
            <div class="sub-content">
              <Select clearable v-model="searchData.pid" placeholder="全部">
                <Option value="人体成分">人体成分</Option>
                <Option value="血压">血压</Option>
                <Option value="血氧">血氧</Option>
                <Option value="血糖">血糖</Option>
                <Option value="尿酸">尿酸</Option>
                <Option value="血脂">血脂</Option>
                <Option value="体质">体质</Option>
                <Option value="握力">握力</Option>
                <Option value="肺活量">肺活量</Option>
                <Option value="平衡能力">平衡能力</Option>
              </Select>
            </div>
          </Col>
          <Col span="9" offset="1">
            <span class="sub-label">上传日期：</span>
            <div class="sub-content">
              <Select clearable v-model="searchData.scrq" placeholder="全部" style="width: 200px;">
                <Option value="本月">本月</Option>
                <Option value="上月">上月</Option>
                <Option value="本周">本周</Option>
                <Option value="上周">上周</Option>
              </Select>
              <DatePicker
                type="daterange"
                placeholder="选择日期"
                style="width: 300px;margin-left:25px;"
                @on-change="onChangeTime"
                :value="queryDate"
              ></DatePicker>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">录入方式：</span>
            <div class="sub-content">
              <Select clearable v-model="searchData.type" placeholder="全部">
                <Option value="0">手动录入</Option>
                <Option value="1">APP 录入</Option>
              </Select>
            </div>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="4">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select
                clearable
                v-model="searchData.pid"
                placeholder="请选择..."
                @on-change="changePro"
              >
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select
                clearable
                v-model="searchData.cid"
                placeholder="请选择..."
                @on-change="changeCity"
              >
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{ v.name }}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select
                clearable
                v-model="searchData.sid"
                placeholder="请选择..."
                @on-change="changeStore"
              >
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{ v.name }}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">顾客名称：</span>
            <div class="sub-content">
              <Select clearable v-model="searchData.member_id" placeholder="请选择...">
                <Option :value="v.id" v-for="v in userList" :key="v.id">{{ v.true_name }}</Option>
              </Select>
            </div>
          </Col>
        </Row>
        <Row class="search-row">
          <Col>
            <span class="sub-label"></span>
            <div class="sub-content">
              <Button @click="handleSearch" type="primary" style="margin-right: 10px;">搜索</Button>
              <Button @click="resetSearch">清空搜索条件</Button>
            </div>
          </Col>
        </Row>
      </div>
      <div class="content-box">
        <h2 class="content-title border-bottom">
          <span>数据列表</span>
          <Button type="primary" @click="showModal = true">数据录入</Button>
        </h2>
        <div class="content-body">
          <Table :columns="recordColumns" :data="recordList"></Table>
          <div>
            <span class="records">共 {{ totalRecords }} 条记录</span>
          </div>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page
                :total="totalRecords"
                :current="searchData.page"
                :page-size="searchData.size"
                @on-change="changeDataPage"
              ></Page>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="数据录入"
      class-name="vertical-center-modal"
      width="900"
      @on-visible-change="handleModalVisible"
      :z-index="1000"
    >
      <template v-if="showSubmit">
        <div class="search-box">
          <div class="search-row">
            <span class="sub-label">项目：</span>
            <div class="modal-sub-content">
              <div class="modal-content">人体成分</div>
            </div>
          </div>
          <div class="search-row">
            <span class="sub-label">顾客：</span>
            <div class="modal-sub-content" style="display: flex; flex-wrap: wrap; gap: 10px;">
              <div
                v-for="(item, index) in selectedUser"
                :key="item.id"
                @click="onClickOne(index)"
                :class="['customer-item-modal', { 'finished': finishedItems.has(item.id), 'active': currentUserIndex === index }]"
              >{{ item.true_name }}</div>
            </div>
          </div>
          <hr class="divider" />
          <Row class="search-row">
            <Col span="11">
              <span class="sub-label">
                <span style="color: red;">*</span>日期：
              </span>
              <div class="modal-sub-content">
                <DatePicker
                  type="date"
                  placeholder="选择日期"
                  :value="modalParams.test_date"
                  style="width: 200px;"
                  @on-change="(value) => { this.modalParams.test_date = value }"
                ></DatePicker>
              </div>
            </Col>
            <Col span="11" offset="1">
              <span class="sub-label">
                <span style="color: red;">*</span>采集时间：
              </span>
              <div class="modal-sub-content">
                <TimePicker
                  type="time"
                  placeholder="选择时间"
                  :value="modalParams.test_time"
                  format="HH:mm"
                  style="width: 200px;"
                  @on-change="(value) => { this.modalParams.test_time = value }"
                ></TimePicker>
              </div>
            </Col>
          </Row>
          <hr class="divider" />
          <div class="search-row" style="overflow: hidden;">
            <span class="sub-label">人体成分数据：</span>
          </div>
          <Row class="search-row">
            <Col span="11">
              <span class="sub-label">体重：</span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.weight" style="width: 200px;"></InputNumber>Kg
              </div>
            </Col>
            <Col span="11" offset="1">
              <span class="sub-label">体水分率：</span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.body_water" style="width: 200px;"></InputNumber>%
              </div>
            </Col>
          </Row>
          <Row class="search-row">
            <Col span="11">
              <span class="sub-label">体格指数：</span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.build" disabled style="width: 200px;"></InputNumber>
              </div>
            </Col>
            <Col span="11" offset="1">
              <span class="sub-label">
                <span style="color: red;">*</span>骨骼肌率：
              </span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.muscull_skeleti" style="width: 200px;"></InputNumber>%
              </div>
            </Col>
          </Row>
          <Row class="search-row">
            <Col span="11">
              <span class="sub-label">体脂肪率：</span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.body_fat" style="width: 200px;"></InputNumber>%
              </div>
            </Col>
            <Col span="11" offset="1">
              <span class="sub-label">骨矿含量：</span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.bmc" style="width: 200px;"></InputNumber>Kg
              </div>
            </Col>
          </Row>
          <Row class="search-row">
            <Col span="11">
              <span class="sub-label">内脂指数：</span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.lactone" style="width: 200px;"></InputNumber>
              </div>
            </Col>
            <Col span="11" offset="1">
              <span class="sub-label">基础代谢：</span>
              <div class="modal-sub-content">
                <InputNumber v-model="modalParams.basic" style="width: 200px;"></InputNumber>Kcal
              </div>
            </Col>
          </Row>
        </div>
        <div slot="footer" class="tac">
          <Button @click="onModalBack">返回</Button>
          <Button type="primary" @click="onNextOne">下一个</Button>
        </div>
      </template>
      <template v-if="!showSubmit">
        <div class="search-box">
          <div class="search-row">
            <span class="sub-label">选择顾客：</span>
            <Row class="sub-content">
              <Col span="7">
                <span class="sub-label">省市</span>
                <div class="modal-sub-content">
                  <Select
                    clearable
                    v-model="modalData.pid"
                    placeholder="请选择..."
                    @on-change="changeProModal"
                  >
                    <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </div>
              </Col>
              <Col span="7" offset="1">
                <span class="sub-label">城市</span>
                <div class="modal-sub-content">
                  <Select
                    clearable
                    v-model="modalData.cid"
                    placeholder="请选择..."
                    @on-change="changeCityModal"
                  >
                    <Option :value="v.id" v-for="v in cityListModal" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </div>
              </Col>
              <Col span="7" offset="1">
                <span class="sub-label">门店</span>
                <div class="modal-sub-content">
                  <Select
                    clearable
                    v-model="modalData.sid"
                    placeholder="请选择..."
                    @on-change="changeStoreModal"
                  >
                    <Option :value="v.id" v-for="v in storeListModal" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
          <div class="search-row">
            <span class="sub-label"></span>
            <Row class="sub-content">
              <Col span="7">
                <span class="sub-label">顾客</span>
                <div class="modal-sub-content">
                  <Select
                    v-model="modalData.member_id"
                    @on-change="addCustomer"
                    filterable
                    placeholder="请选择..."
                  >
                    <Option :value="v.id" v-for="v in userListModal" :key="v.id">{{ v.true_name }}</Option>
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
          <div class="search-row">
            <span class="sub-label">顾客列表：</span>
            <div class="sub-content">
              <div class="customer-wrapper">
                <div v-show="!selectedUser.length" style="text-align: center;flex: 1;">暂无数据</div>
                <div
                  v-for="item in selectedUser"
                  :key="item.id"
                  class="customer-item"
                  @click="removeCustomer(item)"
                >
                  <span style="margin-right: 5px;">{{ item.true_name }}</span>
                  <span>
                    <Icon type="ios-close" color="#fdbd33" size="18" />
                  </span>
                </div>
              </div>
              <div style="font-size: 12px;">
                <span style="color:red;margin-right: 5px;">*</span>
                <span>批量录入数量不可超过 10 个</span>
              </div>
            </div>
          </div>
          <div class="search-row">
            <span class="sub-label">监测成分：</span>
            <div class="sub-content">
              <Select clearable v-model="modalMonitor" placeholder="请选择...">
                <Option value="人体成分">人体成分</Option>
                <Option value="血压">血压</Option>
                <Option value="血氧">血氧</Option>
                <Option value="血糖">血糖</Option>
                <Option value="尿酸">尿酸</Option>
                <Option value="血脂">血脂</Option>
                <Option value="体质">体质</Option>
                <Option value="握力">握力</Option>
                <Option value="肺活量">肺活量</Option>
                <Option value="平衡能力">平衡能力</Option>
              </Select>
            </div>
          </div>
        </div>
        <div slot="footer" class="tac">
          <Button @click="showModal = false">取消</Button>
          <Button type="primary" @click="onNextStep">下一步</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import dayjs from 'dayjs';
import vTitle from 'components/topHeader/topHeader';
import qualityService from '@/services/qualityService';
import comparisonService from '@/services/comparisonService';
import memberService from '@/services/memberService';

export default {
	components: { 'v-title': vTitle },
	data() {
		return {
			proList: [],
			cityList: [],
			storeList: [],
			userList: [],
			searchData: {
				page: 1,
				size: 30,
				pid: '',
				cid: '',
				sid: '',
				type: 2,
				start: '',
				end: '',
				member_id: '',
				scrq: '',
			},
			totalRecords: 0,
			recordList: [],
			recordColumns: [
				{ title: '顾客名称', key: 'gkmc', align: 'center' },
				{ title: '年龄', key: 'nl', align: 'center' },
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{ title: '会员类型', key: 'gklx', align: 'center' },
				{ title: '数据上传日期', key: 'sjscrq', align: 'center' },
				{ title: '采集时间', key: 'cjsj', align: 'center' },
				{ title: '采集方式', key: 'cjfs', align: 'center' },
				{ title: '录入人', key: 'lrr', align: 'center' },
				{ title: '体重', key: 'tz', align: 'center' },
				{ title: '体格指数', key: 'tgzs', align: 'center' },
				{ title: '体脂肪率', key: 'tzfl', align: 'center' },
				{ title: '内脂指数', key: 'nzzs', align: 'center' },
				{ title: '体水分率', key: 'tsfl', align: 'center' },
				{ title: '骨骼肌率', key: 'ggjl', align: 'center' },
				{ title: '骨矿含量', key: 'gkhl', align: 'center' },
				{ title: '基础代谢', key: 'jcdx', align: 'center' },
				{
					align: 'center',
					title: '操作',
					render: (h, params) => {
						return h('div', [
							h('a', {
								class: 'icon_edit',
								style: {
									margin: '0 5px',
								},
								on: {
									click: () => {
										this.$router.push({
											name: 'BodyEdit',
											params: { bodyId: params.row.id, member_id: params.row.member_id },
										});
									},
								},
							}),
							h('a', {
								class: 'icon_del',
								style: {
									margin: '0 5px',
								},
								on: {
									click: () => {},
								},
							}),
						]);
					},
				},
			],
			queryDate: [],
			showModal: false,
			cityListModal: [],
			storeListModal: [],
			userListModal: [],
			modalData: {
				pid: '',
				cid: '',
				sid: '',
			},
			selectedUser: [],
			currentUserIndex: -1,
			modalMonitor: '',
			showSubmit: false,
			modalParams: {
				test_date: null,
				test_time: null,
				weight: null,
				body_fat: null,
				lactone: null,
				body_water: null,
				muscull_skeleti: null,
				bmc: null,
				basic: null,
				build: null,
			},
			finishedItems: new Set(),
		};
	},
	computed: {
		currentUser() {
			return this.selectedUser[this.currentUserIndex];
		},
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
		this.handleSearch();
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.searchData.pid,
					cid: this.searchData.cid,
					sid: this.searchData.sid,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		// 根据省份选择城市
		changeProModal() {
			this.cityListModal = this.city[this.modalData.pid];
			this.modalData.cid = '';
		},
		// 根据城市选择门店
		changeCityModal() {
			this.storeListModal = this.store[this.modalData.cid];
			this.modalData.sid = '';
		},
		// 根据门店选择用户
		changeStoreModal() {
			if (this.modalData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.modalData.pid,
					cid: this.modalData.cid,
					sid: this.modalData.sid,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userListModal = data.list;
				});
			} else {
				this.userListModal = [];
			}
		},
		onChangeTime(val) {
			this.searchData.start = val[0];
			this.searchData.end = val[1];
		},
		handleSearch() {
			memberService.getMemberMonitorList(this.searchData).then((data) => {
				this.recordList = data.lbsj.list;
				this.totalRecords = data.lbsj.row_size;
			});
		},
		resetSearch() {
			this.queryDate = [];
			this.searchData = {
				page: 1,
				size: 30,
				pid: '',
				cid: '',
				sid: '',
				type: 2,
				start: '',
				end: '',
				member_id: '',
				scrq: '',
			};
		},
		changeDataPage(page) {
			this.searchData.page = page;
			this.handleSearch();
		},
		onNextStep() {
			if (this.selectedUser.length === 0) {
				this.$Message.error('请选择顾客');
				return;
			}
			if (!this.modalMonitor) {
				this.$Message.error('请选择监测成分');
				return;
			}
			this.onNextOne();
			this.showSubmit = true;
		},
		async onNextOne() {
			if (this.selectedUserIndex > this.selectedUser.length - 1) {
				this.$Message.error('已经是最后一个啦～');
			}
			if (this.currentUserIndex > -1) {
				if (!this.modalParams.test_date) {
					return this.$Message.error('请选择采集日期');
				}
				if (!this.modalParams.test_time) {
					return this.$Message.error('请选择采集时间');
				}
				if (!this.modalParams.muscull_skeleti) {
					return this.$Message.error('请填写骨骼肌率');
				}

				this.modalParams.member_id = this.currentUser.id;
				await memberService.modifyMemberMonitor(this.modalParams);
				this.finishedItems.add(this.currentUser.id);
				this.currentUserIndex === this.selectedUser.length - 1
					? this.$Message.success('已完成所有数据录入，可关闭页面')
					: this.$Message.success('提交成功');
			}

			if (this.currentUserIndex < this.selectedUser.length - 1) {
				this.currentUserIndex += 1;
				memberService.getMemberMonitor({ member_id: this.currentUser.id }).then((data) => {
					if (Object.keys(data).length > 0) {
						this.finishedItems.add(this.currentUser.id);
					}
					for (const key in this.modalParams) {
						this.modalParams[key] = data[key] || null;
					}
					if (!data.test_date || !data.test_time) {
						const now = dayjs();
						this.modalParams.test_date = now.format('YYYY-MM-DD');
						this.modalParams.test_time = now.format('HH:mm');
					}
				});
			}
		},
		onClickOne(index) {
			if (index === this.currentUserIndex) return;
			this.currentUserIndex = index;
			memberService.getMemberMonitor({ member_id: this.currentUser.id }).then((data) => {
				if (Object.keys(data).length > 0) {
					this.finishedItems.add(this.currentUser.id);
				}
				for (const key in this.modalParams) {
					this.modalParams[key] = data[key] || null;
				}
				if (!data.test_date || !data.test_time) {
					const now = dayjs();
					this.modalParams.test_date = now.format('YYYY-MM-DD');
					this.modalParams.test_time = now.format('HH:mm');
				}
			});
		},
		addCustomer(userId) {
			if (this.selectedUser.length === 10) {
				this.$Message.error('批量录入数量不可超过 10 个');
				return;
			}
			if (this.selectedUser.findIndex((item) => item.id === userId) === -1) {
				this.selectedUser.push(this.userListModal.find((item) => item.id === userId));
			}
		},
		removeCustomer(user) {
			this.selectedUser.splice(
				this.selectedUser.findIndex((item) => item.id === user.id),
				1,
			);
		},
		handleModalVisible(visible) {
			if (!visible) {
				this.selectedUser = [];
			}
		},
		onModalBack() {
			this.showSubmit = false;
			this.currentUserIndex = -1;
		},
	},
};
</script>

<style lang="less" scoped>
.search-box {
  padding-bottom: 10px;
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.sub-content {
  margin-left: 100px;
}
.modal-sub-content {
  margin-left: 70px;
}
.content-title {
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customer-wrapper {
  border-radius: 4px;
  border: 1px solid #dcdee2;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.customer-item {
  display: inline-block;
  border-radius: 15px;
  border: 1px solid #fdbd33;
  padding: 5px 10px;
}
.customer-item-modal {
  display: inline-block;
  border-radius: 15px;
  padding: 0 20px;
  line-height: 32px;
  border: 1px solid #a6a6a6;
  cursor: pointer;
  &.finished {
    background-color: #a6a6a6;
    color: #fff;
  }
  &.active {
    background-color: #fdbd33;
    border-color: #fdbd33;
    color: #fff;
  }
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 20px 0;
}
.modal-content {
  background-color: #33a237;
  border-radius: 4px;
  line-height: 32px;
  color: #fff;
  padding: 0 10px;
  display: inline-block;
}
</style>

<style>
.modal-sub-content .ivu-input-number-input {
  vertical-align: baseline;
}
</style>
